<template>
  <!-- Start Page Title Area -->
  <div class="page-title-area">
    <div class="container">
      <div class="page-title-content">
        <h2>Terms & Conditions</h2>
        <ul>
          <li>
            <router-link to="/" class="nav-link">
              <i class="bx bx-home"></i>
              Home
            </router-link>
          </li>
          <li class="active">Terms</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Page Title Area -->




  <!-- Start Terms & Conditions Area -->
  <section class="standard-text-page-area">
    <div class="container">
      <div class="terms-conditions">

        <div class="conditions-content">


          <h2>Terms & Conditions of Digistore24 services</h2>
          <p>As Digistore acts as provider to sell our products, consult their <a href="https://www.digistore24.com/info/terms/1?lang=en" style="text-decoration: underline">terms & conditions</a></p>

          <h2>Using this this website</h2>

          <h3>Introduction</h3>

          <p>The following terms and conditions are set to regulate your access and use of this website. Acceptance of these terms and conditions in their entirety is required for the use of this website. Should you find any part of these terms and conditions unsatisfactory or disagree with them, you are advised against using this website.</p>

          <p>Usage of this website is restricted to individuals who are 13 years of age or older. By engaging with this website, you confirm and guarantee that you meet or exceed the age requirement of 13 years.</p>


          <h3>Naming</h3>

          <p>For ease of reference, the mention of "Bits&amp;Bytes" within these terms is indicative of the individual {{ decodeHex('416e6472656173204865726e697473636865636b') }}.</p>


          <h3>License to use website</h3>


          <p>All intellectual property rights for the website and its content, unless specified otherwise, are owned by Bits&amp;Bytes and/or its licensors. This includes certain images on the website that are specifically licensed for its use and are additionally governed by their respective terms. The intellectual property rights mentioned are protected and reserved, as detailed in the license that follows and the restrictions outlined herein.</p>

          <p>You are permitted to view, download solely for caching purposes, and print pages from the website for your personal use only, adhering to the limitations specified here and in other sections of these terms and conditions.</p>




          <p>The following actions are prohibited:</p>

          <ul>
            <li>Distribute content from this website again (including on a different website);</li>
            <li>Offer for sale, lease, or sub-lease content from the website;</li>
            <li>Use any content on this website for any commercial gain through reproduction, duplication, copying, or any other form of exploitation;</li>
            <li>Share content from this website unless it is explicitly provided for the purpose of sharing.</li>
          </ul>

          <h3>Guidelines for Use</h3>

          <p>It is prohibited to utilize this website in a manner that could damage the website or hinder its availability or accessibility; or in any manner that is illegal, unlawful, deceitful, or harmful, or in association with any illegal, unlawful, deceitful, or harmful acts or purposes.</p>

          <p>Engaging with this website to store, copy, host, transmit, send, utilize, publish, or distribute any content that includes or links to malicious computer software such as spyware, viruses, Trojan horses, worms, keystroke loggers, rootkits, or any other harmful computer software is strictly forbidden.</p>

          <p>Without the explicit written permission of Bits&amp;Bytes, you are not allowed to engage in any forms of systematic or automated data collection activities (including, but not limited to, scraping, data mining, data extraction, and data harvesting) on or regarding this website.</p>

          <p>Utilizing this website for the purpose of sending or transmitting unsolicited commercial messages is not permitted.</p>



          <h3>Access Limitations</h3>

          <p>Certain sections of this website have limited access. Bits&amp;Bytes has the authority to limit access to specific parts of this website or to the website as a whole, at its own discretion.</p>

          <p>Should Bits&amp;Bytes grant you a user ID and password for entry to restricted sections of this website or for other services or content, you are responsible for maintaining the confidentiality of both the user ID and password.</p>

          <p>In its own judgment, Bits&amp;Bytes has the right to deactivate your user ID and password at any time, without providing notice or a reason.</p>



          <h3>Disclaimer of Warranties</h3>

          <p>The website is offered on an “as is” basis with no representations or guarantees, either expressed or implied. Bits&amp;Bytes does not make any claims or guarantees regarding the website or the content and materials available on it.</p>

          <p>Not to limit the broad statement above, Bits&amp;Bytes does not assure that:</p>

          <ul>
            
            <li>the website will continuously be accessible, or be available at any specific time; or</li>

            <li>the data presented on this website is exhaustive, accurate, entirely correct, or not misleading.</li>

            <li>Training materials do not ensure a perfect match for any third-party examinations, nor is there a promise to encompass all exam topics. Bits&amp;Bytes does not pledge successful exam outcomes, and exam fees are not included in Bits&amp;Bytes’s services.</li>

            <li>Nothing on this website is intended to be, or should be considered, professional advice.</li>

          </ul>


          <p>We reserve the right to delete answers and statistics after 24 months of inactivity.</p>

          
          <h3>Exclusion of Liability</h3>

          <p>Under no circumstances shall Bits&amp;Bytes be liable to you (whether under contract law, tort law, or any other legal theory) in relation to your use of, or otherwise in connection with, this website for:</p>

          <ul>
            <li>any direct loss, in cases where the website’s services are provided for free;</li>

            <li>any indirect, special, or consequential loss; or</li>

            <li>any business-related losses, including but not limited to loss of profits, revenue, savings, business, contracts, goodwill, or data, or data corruption.</li>

            <li>These exclusions of liability stand even if Bits&amp;Bytes has been explicitly advised of the potential loss.</li>
          </ul>


          <h3>Exclusions</h3>

          <p>There are no conditions within this website’s disclaimer that will negate or confine any guarantee implied by law in cases where negating or confining such guarantee would be considered illegal. Similarly, nothing in this disclaimer will limit Bits&amp;Bytes’s accountability for:</p>

          <ul>

            <li>death or bodily harm resulting from the negligence of Bits&amp;Bytes;</li>

            <li>deceit or deceitful misrepresentation by Bits&amp;Bytes; or</li>

            <li>any situation where it would be against the law for Bits&amp;Bytes to dismiss or constrain its accountability, or even attempt to do so.</li>

          </ul>


          <h3>Acceptability</h3>

          <p>By engaging with this website, you acknowledge that the limitations of liability declared in this website’s disclaimer are acceptable.</p>

          <p>If you believe these limitations are not acceptable, you are advised not to use this website.</p>



          <h3>Third-Party Affiliations</h3>

          <p>You acknowledge that Bits&amp;Bytes, being an entity with limited liability, has a vested interest in minimizing the personal liability of its directors and staff. You consent not to hold any of Bits&amp;Bytes’s directors or staff personally liable for any damages you incur in relation to the website.</p>

          <p>You concur that the exclusions of warranties and limitations of liability stated in this website’s disclaimer will also safeguard Bits&amp;Bytes’s directors, employees, agents, subsidiaries, successors, assigns, and subcontractors, in addition to Bits&amp;Bytes itself.</p>

          <h3>Product Purchases and Payments</h3>

          <p>Products listed on the website are available for purchase via a third-party company, Digistore24. All payments are processed by this company, not by Bits&amp;Bytes, which means any financial disputes or issues should be directed to Digistore24. Bits&amp;Bytes assumes no responsibility for the proper execution of payments and cannot be held accountable for any related issues.</p>

          <p>Access to certain sections of the website is granted through purchasing products on Digistore24’s site. To ensure valid access to this website after purchasing products from Digistore24, you agree and confirm to use the same email address for the purchase that you used to register on this website.</p>

          <p>The processing of payment-related data by Digistore24 is governed by their respective terms and conditions and data processing policies.</p>

          <h3>Validity of Provisions</h3>

          <p>Should any part of this website disclaimer be deemed unenforceable by relevant laws, this will not impact the enforceability of the remaining provisions of this website disclaimer.</p>


          <h3>Protection Clause</h3>

          <p>By agreeing to these terms, you commit to safeguarding Bits&amp;Bytes against all types of losses, damages, costs, liabilities, and expenses (including, but not limited to, legal fees and any amounts paid by Bits&amp;Bytes to settle claims or disputes based on the counsel of Bits&amp;Bytes’s legal advisors) that arise from any violation by you of these terms and conditions or any allegations of such a violation.</p>

          <h3>Terms Violation Consequences</h3>

          <p>Bits&amp;Bytes reserves the right, without limiting its other remedies, to take appropriate actions in response to any violations of these terms and conditions. This may include suspending your access, banning you from the website, blocking your IP address, contacting your internet service provider to block your access, and/or initiating legal proceedings against you.</p>

          <p>A violation leading to restricted access does not affect any fees or subscriptions paid; these are non-refundable in the event of misuse.</p>

          <h3>Terms Modification</h3>

          <p>Bits&amp;Bytes retains the right to modify these terms and conditions periodically. Such modifications become effective upon their posting on this website. It is advised to visit this page frequently to stay updated with the latest terms.</p>

          <h3>Rights Allocation</h3>

          <p>Bits&amp;Bytes is entitled to assign, subcontract, or handle its rights and/or duties under these terms without requiring your consent or notice.</p>

          <p>You are not permitted to assign, subcontract, or manage your rights and/or obligations under these terms.</p>

          <h3>Divisibility</h3>

          <p>If any court or competent authority finds any provision of these terms to be invalid or unenforceable, the remaining terms shall persist in full force and effect. Should any unenforceable provision become valid and enforceable upon the removal of part of it, the said part shall be considered omitted, with the remainder of the provision still in effect.</p>




          <h3>Complete Agreement</h3>

          <p>This document and the <router-link to="/privacy" class="text-decoration-underline">privacy policy</router-link> linked here represent the full agreement between you and Bits&amp;Bytes concerning your use of the website. This agreement overrides any prior discussions or agreements regarding your website usage.</p>

          <h3>Governing Law and Jurisdiction</h3>

          <p>The interpretation and enforcement of these terms and conditions will be in line with German law. Any disputes arising from these terms are exclusively subject to the jurisdiction of the courts in Germany, specifically in Erding.</p>

          <h3>Official Registrations</h3>

          <p>Bits&amp;Bytes is officially registered as a trading and consulting entity within Germany, possessing the necessary authorization for its services.</p>

          <p>Payment processed by <a href="https://www.digistore24.com/" target="_blank">Digistore24</a></p>

          <h3>Bits&amp;Bytes’s details</h3>

          <p>The full name of the operator: {{ decodeHex('416e6472656173204865726e697473636865636b') }}.</p>

          <p>Address: {{ decodeHex('45736368656e7374722e203234') }}, {{ decodeHex('38353436342046696e73696e672c204765726d616e79') }}.</p>

          <p>Support by email: {{ decodeHex('737570706f72744062697473616e6462797465732e61636164656d79') }}</p>

          <p>Ust ID DE181229122</p>

        </div>
      </div>
    </div>
  </section>
  <!-- End Terms & Conditions Area -->



</template>

<script>
import Utils from '@/views/Utils.vue'

export default {
  inject: ['sectionCat'],
  mixins: [Utils],

  components: {
  },



  created() {

    window.scrollTo(0,0); // scroll to top


  },



}

</script>